import React, { useEffect, useState } from "react";
import {
  Button,
  Input,
  Table,
  Space,
  Alert,
  Form,
  message,
  Popover,
  Tag,
  Modal,
} from "antd";
import { Navigate, useNavigate, useParams } from "react-router";
import { Section } from "components";
import { useRequest } from "repositories";
import { usePagination, useAuth } from "hooks";
import { formatCurrency } from "utils/helpers";
import moment from "moment";

import { FilterContainer } from "./styles";

const steps = [
  {
    title: "Projeto",
    content: "First-content",
    description: "Selecione o Projeto",
  },
  {
    title: "Itens",
    content: "Second-content",
    description: "Escolha as linhas / produtos",
  },
  {
    title: "Complementos",
    content: "Last-content",
    description: "Preencha o formulário",
  },
  {
    title: "Freelancers",
    content: "Last-content",
    description: "Preencha o formulário Funcional",
  },
];

const ProductSelectionRequest = ({
  nextStep,
  prevStep,
  productSelected,
  project,
  currentProducts,
}: any) => {
  const [clients, setClients] = useState<any>([]);
  const [search, setSearch] = useState("");
  const [requestModal, setRequestModal] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [rowProductSelected, setRowProductSelected] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<any>([]);
  const [alertMessage, setAlertMessage] = useState("");
  const [selectedRowStatus, setSelectedRowStatus] = useState(true);

  const { roles: authRoles, hasRole } = useAuth();
  const repository = useRequest();
  const pagination = usePagination();

  const requestsColumns: any = [
    {
      title: "Emissão",
      dataIndex: "issueDate",
      render: (_: any, record: any) => {
        return record.issueDate
          ? moment(record.issueDate).format("DD/MM/YYYY")
          : "";
      },
    },
    {
      title: "Vencimento",
      dataIndex: "maturity",
      render: (_: any, record: any) => {
        return record.maturity
          ? moment(record.maturity).format("DD/MM/YYYY")
          : "";
      },
    },
    {
      title: "Produto",
      dataIndex: "productName",
    },
    {
      title: "Prestador",
      dataIndex: "typeProvider",
      render: (_: any, record: any) => {
        return record.typeProvider === "SUPPLIER" ? "Fornecedor" : "Freelancer";
      },
    },
    {
      title: "Tipo",
      dataIndex: "typeRequest",
      render: (_: any, record: any) => {
        if (record.typeRequest === "SIMPLE") return "Simples";
        return "Composta";
      },
    },
    {
      title: "Categoria",
      dataIndex: "category",
    },
    {
      title: "Alocação",
      dataIndex: "allocation",
    },
    {
      title: "Local",
      dataIndex: "buName",
    },
    {
      title: "Cliente",
      dataIndex: "client",
    },
    {
      title: "Timing",
      dataIndex: "timing",
      render: (_: any, record: any) => {
        if (record.timing === "PLANEJADA")
          return <Tag color="green">Planejada</Tag>;

        return <Tag color="red">Não planejada</Tag>;
      },
    },
    {
      title: "Fatura",
      dataIndex: "paymentType",
    },
    {
      title: "Valor da requisição",
      dataIndex: "totalCost",
      render: (_: any, record: any) => {
        return formatCurrency(record?.totalCost);
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (_: any, record: any) => {
        switch (record.status) {
          case "NEW":
            return <Tag color="cyan">Novo</Tag>;
          case "IN_QUOTATION":
            return <Tag color="yellow">Em cotação</Tag>;
          case "APPROVED":
            return <Tag color="green">Aprovado</Tag>;
          case "REFUSED":
            return <Tag color="orange">Reprovada</Tag>;
          case "CANCELED":
            return <Tag color="red">Cancelada</Tag>;
          case "ON_APPROVAL":
            return <Tag color="yellow">Em aprovação</Tag>;
          case "PAID_OUT":
            return <Tag color="green">Pago</Tag>;
          default:
            return record.status;
        }
      },
    },
  ];

  const columns: any = [
    {
      title: "Id da linha TOTVS",
      dataIndex: "idLinha",
    },
    {
      title: "Tipo do Faturamento",
      dataIndex: "paymentType",
    },
    {
      title: "Produto",
      dataIndex: "productDescription",
    },
    {
      title: "Categoria",
      dataIndex: "categoria",
    },
    {
      title: "Escopo da linha",
      dataIndex: "technicalScope",
      key: "technicalScope",
      render: (_: any, record: any) => {
        return (
          <Popover
            content={record.technicalScopeDescription}
            overlayStyle={{ maxWidth: "800px" }}
          >
            {record.technicalScope}
          </Popover>
        );
      },
    },
    {
      title: "Quantidade",
      dataIndex: "amount",
    },
    {
      title: "Diárias",
      dataIndex: "daily",
    },
    {
      title: "Custo Unítario",
      dataIndex: "unitCost",
      render: (_: any, record: any) => {
        return formatCurrency(record.unitCost);
      },
    },
    {
      title: "Custo total",
      dataIndex: "totalCost",
      render: (_: any, record: any) => {
        return formatCurrency(record.totalCost);
      },
    },
    {
      title: "Já tem requisições?",
      dataIndex: "withoutRequest",
      align: "center",
      render: (_: any, record: any) => {
        return record.withoutRequest ? (
          <Tag
            color="green"
            style={{ cursor: "pointer" }}
            onClick={() => openRequests(record.projectRequestItems.filter((item: any) => item.status !== "CANCELED"))}
          >
            Sim
          </Tag>
        ) : (
          <Tag color="default">Não</Tag>
        );
      },
    },
    {
      title: "Requisições",
      dataIndex: "approvalRequests",
      render: (_: any, record: any) => {
        return formatCurrency(record.approvalRequests);
      },
    },
    {
      title: "Saldo da linha",
      dataIndex: "lineBalance",
      render: (_: any, record: any) => {
        return (
          <span style={{ whiteSpace: "nowrap" }}>
            {formatCurrency(record.lineBalance)}
          </span>
        );
      },
    },
  ];

  const openRequests = (requests: any) => {
    setRequestModal(requests);
    setIsModalOpen(true);
  };

  const { id, projectRevisionId } = project;
  const requestItens = async () => {
    const currentPage = pagination.data.current ?? 0;
    const params: any = {
      search: search,
      projectId: id,
      projectRevisionId,
      page: currentPage === 0 ? currentPage : currentPage - 1,
      size: 10000,
    };
    const response = await repository.findProjectItems(params);
    if (!response) return;

    if (response?.content?.length === 0) {
      message.error("Escolha um projeto com linhas");
      prev();
    }
    const mappedData = response.content[0]?.items?.filter((item: any) => item.productStatus === true)?.map((request: any) => ({
      id: String(request.id),
      key: request.id,
      idLinha: request.lineIdErp,
      productIdErp: request.productIdErp,
      paymentType: request.billingType === "0" ? "TV1" : "Cliente",
      productId: request.productId,
      costCenterId: request.costCenter.id,
      productDescription: request.productDescription,
      categoria: request.categories[0].description,
      technicalScope: request?.description?.slice(0, 10) + " ...",
      technicalScopeDescription: request?.description,
      amount: request.amount,
      daily: request.daily,
      unitCost: request.unitCost,
      unitSale: request.unitSale,
      totalCost: request.totalCost,
      withoutRequest: request.existsRequisitions,
      packageOrApportionment: request.activePackageOrRating,
      approvalRequests: formatCurrency(
        request.valueApprovedRequisitions
          ? request.valueApprovedRequisitions
          : 0
      ),
      lineBalance: request.valueApprovedRequisitions
        ? request.balanceLine
        : request.totalCost,
      projectRequestItems: request.projectRequestItems,
      executionType: request.executionType,
      productStatus: request.productStatus,
      status: request.status,
    }));
    setClients(mappedData.filter((data: any) => data.status));

    if (currentProducts?.length > 0) {
      setRowProductSelected(currentProducts);
      const mappedKeys = currentProducts.map((product: any) => product.key);
      setSelectedRowKeys(mappedKeys);
    }

  };

  useEffect(() => {
    if (pagination.data.current) requestItens();
  }, [pagination.data, search]);

  const onSearch = (value: string) => setSearch(value);

  if (authRoles && !hasRole(authRoles?.create_request_simple)) {
    return <Navigate to="/acesso-negado" />;
  }

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
    description: item.description,
  }));

  function todosProdutosIguais(array: any) {
    for (let i = 1; i < array?.length; i++) {
      if (array[i].productId !== array[0].productId) {
        return true;
      }
    }
    return false;
  }

  const diferenteDeE = (products: any) => {
    for (let i = 0; i < products?.length - 1; i++) {
      if (products[i].executionType !== "E") {
        return true;
      }
    }
    return false;
  };

  const statusValido = (products: any) => {
    if (!products[0]?.productStatus) {
      return true;
    }
  };

  const next = () => {
    if (rowProductSelected?.length > 0 && selectedRowStatus) {
      productSelected(rowProductSelected);
      nextStep();
    } else {
      if (!selectedRowStatus && rowProductSelected?.length > 1) {
        setAlertMessage("Selecione produtos ou linhas do mesmo item!");
      } else {
        setAlertMessage("Selecione pelo menos um produto ou linha!");
      }
    }
  };

  const prev = () => {
    productSelected();
    prevStep();
  };

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any) => {
      setRowProductSelected(selectedRows);
      setSelectedRowKeys(selectedRowKeys);
      if (!todosProdutosIguais(selectedRows)) {
        setAlertMessage("");
        setSelectedRowStatus(true);
        if (!diferenteDeE(selectedRows)) {
          setAlertMessage("");
          setSelectedRowStatus(true);
          if (!statusValido(selectedRows)) {
            setAlertMessage("");
            setSelectedRowStatus(true);
          }
          if (statusValido(selectedRows) && selectedRows?.length > 0) {
            setAlertMessage(
              "Linha sem produto, atualize a linha do produto no GFP"
            );
            setSelectedRowStatus(false);
          }
        } else {
          setAlertMessage(
            "Por favor, selecione linhas que não sejam de execução interna [hora homem]"
          );
          setSelectedRowStatus(false);
        }
      } else {
        setAlertMessage("Linhas selecionadas não são o mesmo produto");
        setSelectedRowStatus(false);
      }
    },
  };

  return (
    <>
      <Section>
        <div style={{ marginTop: 24 }}>
          <Form layout="vertical">
            <>
              <FilterContainer>
                <Input.Search
                  placeholder="Pesquise pelo nome"
                  onSearch={onSearch}
                  enterButton
                />
              </FilterContainer>
              <Table
                rowSelection={{
                  type: "checkbox",
                  selectedRowKeys,
                  ...rowSelection,
                }}
                columns={columns}
                dataSource={clients}
                pagination={{ showSizeChanger: true }}
              />

              {alertMessage ? (
                <Space
                  direction="vertical"
                  style={{ width: "100%", marginBottom: "15px" }}
                >
                  <Alert message={alertMessage} type="error" />
                </Space>
              ) : (
                <></>
              )}

              <Button style={{ margin: "0 4px" }} onClick={() => prev()}>
                Voltar
              </Button>
              <Button
                style={{ margin: "0 4px" }}
                type="primary"
                onClick={() => next()}
              >
                Próximo
              </Button>
            </>
          </Form>
        </div>
      </Section>
      <Modal
        title="Requisições"
        className="horizontal-scrollable-modal"
        open={isModalOpen}
        onOk={() => setIsModalOpen(false)}
        onCancel={() => setIsModalOpen(false)}
        width={1300}
      >
        <Table
          columns={requestsColumns}
          dataSource={requestModal}
          pagination={false}
          style={{ overflowX: "auto" }}
        />
      </Modal>
    </>
  );
};

export default ProductSelectionRequest;
